import React, { useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import WindMillImg from "../images/orb.jpg"
import PageHeader from "../partialPages/PageHeader"
import { Accordion, Card, Alert, Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperclip } from "@fortawesome/free-solid-svg-icons"
import {
  who_are_We,
  what_we_build,
  about_our_products,
  our_team,
} from "../data/commonData"
import Axios from "axios"

const JobDetails = ({ pageContext: { page } }) => {
  const pgDesc = `Vulcan AI is looking for passionate ${page.title}s`
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [linkedin, setLinkedin] = useState("")
  const [note, setNote] = useState("")
  const [resumeFile, setResumeFile] = useState({ name: "" })
  const [resume, setResume] = useState("")
  const [coverLetterFile, setcoverLetterFile] = useState({ name: "" })
  const [coverLetter, setcoverLetter] = useState("")

  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)
  const [submitMessage, setSubmitMessage] = useState(false)
  const handleSubmit = evt => {
    evt.preventDefault()
    if (!resume) {
      setShowError(true)
      setShowSuccess(false)
      setSubmitMessage("Please select a resume file to upload")
      return
    }
    let fd = new FormData()
    fd.append(
      "body",
      new Blob(
        [
          JSON.stringify({
            firstName,
            lastName,
            email,
            phone,
            linkedin,
            note,
            jobTitle: page.title,
          }),
        ],
        {
          type: "application/json",
        }
      )
    )
    fd.append("resumeFile", resumeFile)

    if (coverLetter) fd.append("coverLetter", coverLetterFile)
    // Axioos
    Axios.post(
      "https://vulcanwebcontact.azurewebsites.net/api/apply-job?code=XXBxGBYJZNkHB6Tt1qfEija7KWpsur0aC8blP4UaF90ceBg7GaSbmw==",
      fd
    )
      .then(res => {
        console.log(res.data)

        setShowSuccess(true)
        setShowError(false)
        setSubmitMessage(res.data)
      })
      .catch(err => {
        setShowError(true)
        setShowSuccess(false)
        setSubmitMessage(err.data)
      })
  }

  const mainContent = (
    <>
      <div className="mt-4"></div>
      <p className=" text-justify  d-block d-md-none">{page.jobDescription}</p>
      <p className=" text-justify d-none d-md-block">{page.jobDescription}</p>
      <Accordion id="accordion" defaultActiveKey="0" variant="dark">
        <div className="mt-5 mb-4">
          <Card className="mb-4 bg-white" style={{ border: "1px solid #ccc" }}>
            <Accordion.Toggle
              eventKey="0"
              as={Card.Header}
              variant="link"
              style={{ cursor: "pointer" }}
              className="text-primary  px-0 py-3 bg-white"
            >
              <h3 className="px-5 d-none d-md-block">Responsibilities</h3>
              <h3 className="px-4 d-block d-md-none">Responsibilities</h3>
            </Accordion.Toggle>

            <Accordion.Collapse appear={true} eventKey="0">
              <Card.Body>
                <div className="job-list">
                  <ul>
                    {page.responsibilities &&
                      page.responsibilities.map((res, index) => (
                        <li key={index}>{res}</li>
                      ))}
                  </ul>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card className="mb-4 bg-white" style={{ border: "1px solid #ccc" }}>
            <Accordion.Toggle
              eventKey="1"
              as={Card.Header}
              variant="link"
              style={{ cursor: "pointer" }}
              className="text-primary  px-0 bg-white py-3"
            >
              <h3 className="px-5 d-none d-md-block">Requirements</h3>
              <h3 className="px-4 d-block d-md-none">Requirements</h3>
            </Accordion.Toggle>

            <Accordion.Collapse appear={true} eventKey="1">
              <Card.Body>
                <div className="job-list">
                  <ul>
                    {page.requirements &&
                      page.requirements.map((req, index) => (
                        <li key={index}>{req}</li>
                      ))}
                  </ul>
                </div>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          {page.preferred.length ? (
            <Card className="bg-white" style={{ border: "1px solid #ccc" }}>
              <Accordion.Toggle
                eventKey="2"
                as={Card.Header}
                variant="link"
                style={{ cursor: "pointer" }}
                className="text-primary  bg-white px-0 py-3"
              >
                <h3 className="px-5 d-none d-md-block">Preferred</h3>
                <h3 className="px-4 d-block d-md-none">Preferred</h3>
              </Accordion.Toggle>

              <Accordion.Collapse appear={true} eventKey="2">
                <Card.Body>
                  <div className="job-list">
                    <ul>
                      {page.preferred &&
                        page.preferred.map((pref, index) => (
                          <li key={index}>{pref}</li>
                        ))}
                    </ul>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ) : null}{" "}
        </div>
      </Accordion>
      <form onSubmit={handleSubmit} id="applyForm" className="pb-5">
        <Row>
          <Col sm="6">
            <div className="form-group  pb-2">
              <label htmlFor="firstName">
                First Name
                <span className="required-aestrisk">*</span>
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                required
                className="form-control"
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="form-group text-left pb-2">
              <label htmlFor="lastName">
                Last Name <span className="required-aestrisk">*</span>
              </label>

              <input
                type="text"
                name="lastName"
                id="lastName"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
                className="form-control"
                required
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="form-group text-left pb-2">
              <label htmlFor="email">
                Email <span className="required-aestrisk">*</span>
              </label>

              <input
                type="email"
                name="email"
                id="email"
                required
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="form-control"
              />
            </div>
          </Col>
          <Col sm="6">
            <div className="form-group text-left pb-2">
              <label htmlFor="tel">
                Phone <span className="required-aestrisk">*</span>
              </label>

              <input
                type="tel"
                name="tel"
                id="tel"
                required
                value={phone}
                onChange={e => setPhone(e.target.value)}
                className="form-control"
                pattern="+[0-9]{2}[0-9]{10}"
              />
            </div>
          </Col>
          <Col md="11" lg="5">
            <div className="form-group  pb-2">
              <label htmlFor="resume" className="form-group-text  ">
                Resume <span className="required-aestrisk">*</span>
              </label>

              <input
                type="text"
                name="resume"
                id="resume"
                value={resume
                  .split("\\")
                  .pop()
                  .split("/")
                  .pop()}
                readOnly
                required
                className="form-control"
              />
              <input
                type="file"
                name="resume-file"
                className="d-none"
                onChange={e => {
                  console.log(e.target.files[0])
                  setResume(e.target.value)
                  setResumeFile(e.target.files[0])
                }}
                accept=".doc, .docx, .pdf"
                id="resume-file"
              />
            </div>
          </Col>
          <Col sm="1" style={{ paddingLeft: "0.5rem" }}>
            <div className="text-left d-none d-sm-block mx-auto">
              <button
                className="attach-file btn btn-outline-primary"
                type="button"
                onClick={e => {
                  document.getElementById("resume-file").click()
                  e.preventDefault()
                }}
                style={{
                  height: "100%",
                  width: "100%",
                  minWidth: "40px !important",
                  minWidth: 40,
                  borderRadius: 3,
                  marginTop: 34,
                }}
              >
                <FontAwesomeIcon icon={faPaperclip}></FontAwesomeIcon>
              </button>
            </div>
            <div
              className="d-block d-sm-none"
              style={{ paddingLeft: "0.5rem" }}
            >
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={e => {
                  document.getElementById("resume-file").click()
                  e.preventDefault()
                }}
                style={{
                  height: "100%",
                  marginTop: "-1rem",
                  marginBottom: "0.6rem",
                }}
              >
                Attach <FontAwesomeIcon icon={faPaperclip}></FontAwesomeIcon>
              </button>
            </div>
          </Col>
          <Col md="11" lg="5">
            <div className="form-group  pb-2">
              <label htmlFor="coverLetter" className="   text-left">
                Cover Letter
              </label>

              <input
                type="text"
                name="coverLetter"
                id="coverLetter"
                className="form-control"
                readOnly
                value={coverLetter
                  .split("\\")
                  .pop()
                  .split("/")
                  .pop()}
              />
              <input
                type="file"
                name=""
                className="d-none"
                onChange={e => {
                  console.log(e.target.files[0])
                  setcoverLetter(e.target.value)
                  setcoverLetterFile(e.target.files[0])
                }}
                accept=".doc, .docx, .pdf"
                id="cl-file"
              />
            </div>
          </Col>
          <Col sm="1" style={{ paddingLeft: "0.5rem" }}>
            <div className="text-left d-none d-md-block">
              <button
                className="attach-file btn btn-outline-primary"
                type="button"
                onClick={e => {
                  document.getElementById("cl-file").click()
                  e.preventDefault()
                }}
                style={{
                  height: "100%",
                  width: "100%",
                  minWidth: 40,
                  borderRadius: 3,
                  marginTop: 34,
                }}
              >
                <FontAwesomeIcon icon={faPaperclip}></FontAwesomeIcon>
              </button>
            </div>
            <div
              className="d-block d-md-none"
              style={{ paddingLeft: "0.5rem" }}
            >
              <button
                className="btn btn-outline-primary"
                type="button"
                onClick={e => {
                  document.getElementById("cl-file").click()
                  e.preventDefault()
                }}
                style={{
                  height: "100%",
                  marginTop: "-1rem",
                  marginBottom: "0.6rem",
                }}
              >
                Attach <FontAwesomeIcon icon={faPaperclip}></FontAwesomeIcon>
              </button>
            </div>
          </Col>
          <Col sm="12">
            <div className="form-group text-left pb-2">
              <label htmlFor="linkedin" className="text-left">
                Linkedin
              </label>

              <input
                type="text"
                name="linkedin"
                id="linkedin"
                value={linkedin}
                onChange={e => setLinkedin(e.target.value)}
                placeholder="www.linkedin.com/john-doe"
                className="form-control"
              />
            </div>
          </Col>
          <Col sm="12">
            <div className="form-group text-left pb-2">
              <label htmlFor="note" className="">
                {" "}
                Exp Summary
              </label>

              <textarea
                type="text"
                name="note"
                id="note"
                placeholder="Provide a brief of your experience. "
                value={note}
                onChange={e => setNote(e.target.value)}
                className="form-control"
              ></textarea>
            </div>
          </Col>
        </Row>

        <div className="w-100 text-center">
          <input
            type="submit"
            value="Apply"
            className="btn btn-outline-primary nohover text-bold  text-primary apply-button mb-2 mt-4 mx-auto"
          />
        </div>
        <Alert show={showSuccess} variant="success">
          <h5 className="m-auto">
            {submitMessage || "Contact information submitted."}
          </h5>
        </Alert>
        <Alert show={showError} variant="danger">
          <h4 className="m-auto">{submitMessage || "Error occured"}</h4>
        </Alert>
      </form>
    </>
  )

  return (
    <Layout pageInfo={{ pageName: page.title }} hideTimeline={true}>
      <SEO title={page.title} description={pgDesc} />
      <div className="bg-white">
        <PageHeader
          bgImg={WindMillImg}
          pgTitle={page.title}
          pgDesc={pgDesc}
          titleClass="text-center"
          pClass="text-center"
          overlayOverRide={{ background: "rgba(0, 0, 0, 0.6)" }}
        ></PageHeader>
        <div className="px-4 d-block d-lg-none">
          <hr />
        </div>
        <section id="company-profile" className="bg-white my-5">
          <div className="container  d-none d-lg-block">
            <p>{who_are_We}</p>
            <p>{what_we_build}</p>
            <p>{about_our_products}</p>
            <p>{our_team}</p>
          </div>
          {/* <div className="px-4  d-none d-md-block d-lg-block">
          <p>{who_are_We}</p>
          <p>{what_we_build}</p>
          <p>{about_our_products}</p>
          <p>{our_team}</p>
        </div> */}
        </section>

        <section id="job-description" className="bg-white">
          <div className="container d-none d-lg-block">{mainContent}</div>
          <div className="px-4 d-block d-lg-none">{mainContent}</div>
        </section>
      </div>
    </Layout>
  )
}

export default JobDetails
